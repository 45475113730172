import { useEffect } from 'react';

import { usePostAssignmentMutation } from 'services';
import { Form } from 'antd';
import { useDispatch } from 'react-redux';
import { fetchError } from 'slices/commonSlice';
import { useIntl } from 'react-intl';
import { openNotification } from 'utils';

import MediaField from '../MediaField';
import MediaOfferField from '../MediaOfferField';

import AsignItem from './AsignItem';

const ContentAsignment = ({ offerURI, offerID, assignments, disabledAsignment, afterCopyright }) => {
  const [postAssignment] = usePostAssignmentMutation();
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();

  const handleIsEditable = (status) => {
    return afterCopyright?.some((item) => item.field.status === status);
  };
  useEffect(() => {
    form.setFieldsValue({
      assignments: afterCopyright?.reduce((acc, item) => {
        acc[item.field.id] = {
          field: item['@id'],
          user: item.user?.id,
          id: item['@id'],
        };
        return acc;
      }, {}),
    });
  }, [afterCopyright, form]);

  const handleNewAssignment = async ({ data }) => {
    const { error } = await postAssignment({
      data,
    });
    if (error) {
      return dispatch(
        fetchError(
          formatMessage(
            {
              id: 'article.form.fetchError',
            },
            { error: error.status }
          )
        )
      );
    }
    openNotification({
      message: formatMessage({
        id: 'appModule.actionSuccessfully',
      }),
    });
  };
  const handleValuesChange = async (changedValues) => {
    if (changedValues?.assignments) {
      const promises = Object.entries(changedValues.assignments).map(async ([index, value]) => {
        if (value.user) {
          const field = form.getFieldValue(['assignments', index, 'field']);
          const id = form.getFieldValue(['assignments', index, 'id']);

          await handleNewAssignment({
            data: {
              ...(id && { id }),
              articleOffer: offerURI,
              field,
              user: `/api/users/${value.user}`,
            },
          });
        }
      });

      await Promise.all(promises);
    }
  };

  return (
    <div>
      <h3>Madde Atama</h3>
      <MediaField
        offerID={offerID}
        isProcessContinue={false}
        isMediaEditable={false}
      />
      <MediaOfferField
        offerID={offerID}
        isHide
      />
      <Form
        form={form}
        className="gx-mt-5"
        onValuesChange={handleValuesChange}
      >
        {assignments?.map((step) => (
          <AsignItem
            key={step.id}
            field={step['@id']}
            index={step.id}
            label={step.roleText}
            disabled={disabledAsignment}
            status={step.status}
            role={step.role}
            handleIsEditable={handleIsEditable}
          />
        ))}
      </Form>
    </div>
  );
};

export default ContentAsignment;
