import { InboxOutlined } from '@ant-design/icons';
import { Upload, Form, message } from 'antd';
import UploadedFile from 'components/AddNewArticle/UploadedFile';
import { chunkUploadMedia, idCardRequired } from 'utils';
import { useSendArticleContentMutation } from 'services';

const mediaFileType = '.docx, .txt';

const ContentUpload = ({ id, articleTypeID }) => {
  const form = Form.useFormInstance();
  const [sendArticleContent] = useSendArticleContentMutation();
  const isIdCardRequired = idCardRequired.includes(articleTypeID) || false;

  const itemFile = Form.useWatch('itemFile', form);
  const idCard = Form.useWatch('idCard', form);
  const uvkaFile = Form.useWatch('uvkaFile', form);

  const handleChange = (info) => {
    if (info.file.status === 'done') {
      message.destroy();
      message.success('Dosya yüklendi');
    } else if (info.file.status === 'error') {
      message.destroy();
      message.error('Dosya yüklenirken bir hata oluştu');
    } else if (info.file.status === 'uploading') {
      message.loading('Dosya yükleniyor', 0);
    }
  };
  // dosya yükleme işlemi customUpload funksiyonu
  const handleCustomUpload = async ({ file, onSuccess, onError, type, formItemName }) => {
    try {
      const [res] = await chunkUploadMedia('articleContent', [file]);
      const { error } = await sendArticleContent({
        offerID: id,
        body: {
          media: [`/api/media/${res.media.id}`],
          type,
        },
      });
      if (error) {
        form.resetFields([formItemName]);
        onError('error');
      }
      onSuccess('done');
    } catch (error) {
      form.resetFields([formItemName]);
      onError('error');
    }
  };

  return (
    <>
      <Form.Item
        label="Madde Dosyası"
        className="gx-mb-4"
        name="itemFile"
        required
        rules={[
          {
            required: true,
            message: 'Lütfen madde dosyasını yükleyiniz',
          },
        ]}
      >
        <Upload.Dragger
          maxCount={1}
          onChange={handleChange}
          accept={mediaFileType}
          customRequest={({ file, onSuccess, onError }) =>
            handleCustomUpload({
              file,
              onSuccess,
              onError,
              type: 0,
              formItemName: 'itemFile',
            })
          }
          showUploadList={false}
        >
          <>
            {itemFile?.media?.[0] ? (
              <UploadedFile
                content={itemFile?.media[0]?.name}
                id={itemFile.id}
                offerID={id}
                extra={<> </>}
              />
            ) : (
              <>
                <p className="ant-upload-drag-icon">
                  <InboxOutlined />
                </p>
                <p className="ant-upload-text">Dosyayı sürükleyip bırakın ya da tıklayın</p>
                <p className="ant-upload-hint">Dosya boyutu 25MB'ı geçmemelidir.</p>
              </>
            )}
          </>
        </Upload.Dragger>
      </Form.Item>
      <Form.Item
        label="Kimlik Kartı"
        className="gx-mb-4"
        name="idCard"
        rules={[
          {
            required: isIdCardRequired,
            message: 'Lütfen kimlik kartınızı yükleyiniz',
          },
        ]}
      >
        <Upload.Dragger
          maxCount={1}
          onChange={handleChange}
          accept={mediaFileType}
          customRequest={({ file, onSuccess, onError }) =>
            handleCustomUpload({
              file,
              onSuccess,
              onError,
              type: 1,
              formItemName: 'idCard',
            })
          }
          showUploadList={false}
        >
          <>
            {idCard?.media?.[0] ? (
              <UploadedFile
                content={idCard?.media[0]?.name}
                id={idCard.id}
                offerID={id}
                extra={<> </>}
              />
            ) : (
              <>
                <p className="ant-upload-drag-icon">
                  <InboxOutlined />
                </p>
                <p className="ant-upload-text">Dosyayı sürükleyip bırakın ya da tıklayın</p>
                <p className="ant-upload-hint">Dosya boyutu 25MB'ı geçmemelidir.</p>
              </>
            )}
          </>
        </Upload.Dragger>
      </Form.Item>
      <Form.Item
        label="ÜVKA Dosyası"
        className="gx-mb-4"
        name="uvkaFile"
        rules={[
          {
            required: true,
            message: 'Lütfen ÜVKA dosyasını yükleyiniz',
          },
        ]}
      >
        <Upload.Dragger
          maxCount={1}
          accept={mediaFileType}
          multiple={false}
          onChange={handleChange}
          customRequest={async ({ file, onSuccess, onError }) =>
            handleCustomUpload({
              file,
              onSuccess,
              onError,
              type: 3,
              formItemName: 'uvkaFile',
            })
          }
          showUploadList={false}
        >
          <>
            {uvkaFile?.media?.[0] ? (
              <UploadedFile
                content={uvkaFile?.media[0].name}
                id={uvkaFile.id}
                offerID={id}
                extra={<> </>}
              />
            ) : (
              <>
                <p className="ant-upload-drag-icon">
                  <InboxOutlined />
                </p>
                <p className="ant-upload-text">Dosyayı sürükleyip bırakın ya da tıklayın</p>
                <p className="ant-upload-hint">Dosya boyutu 25MB'ı geçmemelidir.</p>
              </>
            )}
          </>
        </Upload.Dragger>
      </Form.Item>
    </>
  );
};

export default ContentUpload;
