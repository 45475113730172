import { useEffect, useMemo, useState } from 'react';

import { Button, Card, Col, Row, Steps } from 'antd';
import { OrderDetailGrid } from 'components/ArticleOrder';
import { useGetAfterCopyRightAssignmentQuery, useGetAsignmentFieldsQuery, useGetCurrentUserQuery, useStartAfterCopyrightDecideProcessMutation } from 'services';
import { afterCopyrightStatus, openNotification } from 'utils';
import { useDispatch } from 'react-redux';
import { fetchError } from 'slices/commonSlice';
import { useIntl } from 'react-intl';
import { CircularProgress } from 'components';
import MediaField from 'components/AfterCopyright/MediaField';
import ContentAsignment from 'components/AfterCopyright/ContentAsignment';
import MediaOfferField from 'components/AfterCopyright/MediaOfferField';
import StatusBar from 'components/StatusBar';

const AfterCopyrightProcessContent = ({ offer, type, committee, disabledStatus }) => {
  const { data: current_user } = useGetCurrentUserQuery();
  const { id: currentUserID, isAdmin } = current_user;

  const { data: assignments, isLoading } = useGetAsignmentFieldsQuery({
    type: type,
    articleOffer: offer && offer.id,
  });

  // Steper icin gereken index degerinin statuye gore getirilmesi
  const [current, setCurrent] = useState(0);
  const currentIndex = useMemo(() => assignments?.findIndex((step) => step.status === offer.status), [assignments, offer.status]);
  const selectedItemStatus = useMemo(() => assignments?.[current - 1]?.status, [assignments, current]);

  const { data: afterCopyright } = useGetAfterCopyRightAssignmentQuery({
    articleOffer: offer && offer.id,
    type: type,
    ...((offer.status === 27 || offer.status === 39) && { onlyActive: true }),
  });

  const userCanDecide = useMemo(() => {
    return assignments?.[current - 1]?.assignments?.some((assignment) => assignment.user.id === currentUserID) || isAdmin;
  }, [current, currentUserID, isAdmin, assignments]);

  const [startAfterCopyrightDecideProcess] = useStartAfterCopyrightDecideProcessMutation();

  const dispatch = useDispatch();
  const { formatMessage } = useIntl();

  const isHideMediaField = useMemo(() => {
    return (offer.status > 37 && offer.status !== 39 && current !== 0) || (offer.status < 37 && offer.status !== 27 && current !== 0);
  }, [offer.status, current]);

  const handleStartProcess = async () => {
    const body = {
      status: 2,
    };
    const { error } = await startAfterCopyrightDecideProcess({ offerID: offer && offer.id, committee: committee, data: body });

    if (error) {
      return dispatch(
        fetchError(
          formatMessage(
            {
              id: 'article.form.fetchError',
            },
            { error: error.status }
          )
        )
      );
    }
    openNotification({
      message: formatMessage({
        id: 'appModule.actionSuccessfully',
      }),
    });
  };
  useEffect(() => {
    if (document) {
      // buradaki id degeri /src/routes/index.js dosyasindaki div in id degerini ifade ediyor
      const element = document.getElementById('routes-wrapper');
      if (element) {
        element.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
        });
      }
    }
  }, [offer.status]);

  useEffect(() => {
    setCurrent(currentIndex + 1);
  }, [currentIndex, offer.status]);

  const afterCopyrightSteps = [
    {
      id: crypto.randomUUID(),
      title: afterCopyrightStatus[disabledStatus],
      style: { cursor: 'pointer' },
    },
    ...(assignments?.map((step, index) => ({
      id: index,
      title: (
        <div>
          {step.roleText}
          <br />
          <small>{step.assignments[0]?.user?.fullName}</small>
        </div>
      ),
      disabled: step.status > offer.status || step.status === disabledStatus,
    })) || []),
  ];
  if (isLoading) return <CircularProgress />;

  return (
    <div className="gx-pb-4">
      <Steps
        progressDot
        onChange={(current) => setCurrent(current)}
        current={current}
        labelPlacement="vertical"
        items={afterCopyrightSteps.sort((a, b) => a.status - b.status)}
      />
      <Card className="gx-p-3">
        <StatusBar status={offer && offer.status} />
        <OrderDetailGrid offer={offer} />
        {current === assignments?.length && offer.status < 37 ? (
          <>
            <MediaField
              description={afterCopyright?.[Number(current) - 1]?.previousAssignment?.description}
              redactionGeneralNote={offer && offer.euControlDescription}
              redactionNote={afterCopyright?.[Number(current) - 1]?.note}
              offerID={offer && offer.id}
              isProcessContinue={selectedItemStatus === offer.status}
              isMediaEditable={false}
              status={offer && offer.status}
            />
            <MediaOfferField
              userCanDecide={userCanDecide}
              offerID={offer && offer.id}
            />
          </>
        ) : (
          isHideMediaField && (
            <MediaField
              description={afterCopyright?.[Number(current) - 1]?.previousAssignment?.description}
              euControlDescription={offer && offer.euControlDescription}
              pubControlDescription={offer && offer.pubControlDescription}
              redactionNote={afterCopyright?.[Number(current) - 1]?.note}
              offerID={offer && offer.id}
              isProcessContinue={selectedItemStatus === offer.status}
              isMediaEditable={current !== 0 && userCanDecide}
              status={offer && offer.status}
            />
          )
        )}
        {/* sadece madde atama asamasinda goruntulenecek bir komponent */}
        {current === 0 && (
          <>
            <ContentAsignment
              offerID={offer && offer.id}
              status={offer && offer.status}
              disabledAsignment={offer.status > disabledStatus}
              offerURI={offer && offer['@id']}
              assignments={assignments}
              afterCopyright={afterCopyright}
            />
            {offer.status === disabledStatus && (
              <Row gutter={24}>
                <Col
                  sm={24}
                  md={24}
                >
                  <Button
                    onClick={handleStartProcess}
                    block
                  >
                    Telif Sonrası Sürecini Başlat
                  </Button>
                </Col>
              </Row>
            )}
          </>
        )}
      </Card>
    </div>
  );
};

export default AfterCopyrightProcessContent;
